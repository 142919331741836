import React from 'react'
import gmail from '../img/social/gmail.svg'
import linkedin from '../img/social/linkedin.svg'
import github from '../img/github-icon.svg'

const SocialLinks = () => {
  return (
    <div className="is-4 social">
      <a 
        title="email"
        href="mailto:ewang718@gmail.com?Subject=Hi Edbert!" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img className="fas fa-lg" src={gmail} alt="Email" style={{ width: '1em', height: '1em' }} />
      </a>
      <a 
        title="linkedin" 
        href="https://www.linkedin.com/in/edbert-wang/" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img src={linkedin} alt="Linkedin" style={{ width: '1em', height: '1em' }} />
      </a>
      <a 
        title="github" 
        href="https://www.github.com/trebde" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img src={github} alt="Github" style={{ width: '1em', height: '1em' }} />
      </a>
    </div>
  )
}

export default SocialLinks;