import React from 'react'

import SocialLinks from './SocialLinks';
import logo from '../img/logo.svg'

const QUOTES = [
  'Edbert is the real deal. He knows what it takes to build great product.',
  'Edbert does more than just listen — he delivers in ways you never even dreamed of.',
  'Edbert can do it all, from design to product to engineering.',
  'Edbert can design & build fully secure & scalable web apps using modern serverless frameworks.',
  'Edbert is a true thought leader when building product and leveraging creativity.',
  'Edbert has blown me away with his commitment to true creative innovation.',
  'Edbert has incredible perspective and his solutions show it.',
]

const Footer = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuote: Math.floor(Math.random() * QUOTES.length),
      currentHue: Math.floor((((((Math.random() * 3) / 18) + (Math.random()*2)) / 5) + 0.24) * 350),
    }
    this.newQuote = this.newQuote.bind(this);
  }

  newQuote() {
    const randInt = Math.floor(Math.random() * QUOTES.length);
    const randHue = Math.floor((((((Math.random() * 3) / 18) + (Math.random()*2)) / 5) + 0.24) * 350);
    if (this.state.currentQuote === randInt || this.state.currentHue === randHue) {
      this.newQuote();
    } else {
      this.setState({
        currentQuote: randInt,
        currentHue: randHue
      });
    }
  }

  render() {
    return (
      <footer className="footer has-text-white-ter" style={{backgroundColor: `hsl(${this.state.currentHue}, 100%, 4%)`, transition: `background-color 0.6s`}}>
        <div className="container content has-text-centered">
          <div className="column">
            <img 
              className="logo-rev" 
              src={logo} 
              width='120' 
              height='120' 
              alt="Edbert's Face" 
              onClick={this.newQuote} />
            <div onClick={this.newQuote} className="footer-quote" style={{overflow: 'hidden'}}>
              <h2 style={{
                color: `hsl(${this.state.currentHue}, 100%, 50%)`, 
                transition: 'color 0.6s',
                fontSize: '3rem', 
                maxWidth: 600, 
                margin: '0 auto',
                overflow: 'text-wrap'
              }}>
                {QUOTES[this.state.currentQuote]}
              </h2>
            </div>
            <br/>
            <SocialLinks />
            <br/>
            <p>Website handmade using <a href="https://www.netlifycms.org">NetlifyCMS</a> &amp; <a href="https://www.gatsbyjs.org/">GatsbyJS</a>, deployed with <a href="https://www.netlify.com">Netlify</a></p>
            {/* <p>
              <a href="/">request a resume</a> | <a href="/">request CMS preview</a> | <a href="/">send edbert a message</a> 
            </p> */}
            <br/>
            <h1><span role="img" aria-label="peace sign">✌</span></h1>
            <br/>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
