import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import SocialLinks from './SocialLinks';

const Navbar = class extends React.Component {
  componentDidMount() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll('.navbar-burger'),
      0
    )
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target
          const $target = document.getElementById(target)

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active')
          $target.classList.toggle('is-active')
        })
      })
    }
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="" title="Logo">
              <img className="logo-rot" src={logo} width='160' height='160' alt="Edbert's Face" />
            </Link>
            {/* Hamburger menu */}
            <div className="navbar-burger burger" data-target="navMenu">
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className="navbar-menu">
            <div className="navbar-start">
              <Link 
                to='/'
                className="navbar-item"
                activeClassName="active-navbar-item"
              >
                Projects
              </Link>
              <Link 
                to='/tags'
                className="navbar-item"
                activeClassName="active-navbar-item"
              >
                Capabilities
              </Link>
              <Link 
                to='/about'
                className="navbar-item"
                activeClassName="active-navbar-item"
              >
                Talks
              </Link>
            </div>
            <div className="navbar-end has-text-centered">
              <SocialLinks />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar;
